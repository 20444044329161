export const useLoqate = () => {
  const LOQATE_KEY = useRuntimeConfig().public.LOQATE_KEY
  const ENDPOINT_EMAIL_VALIDATION =
    "https://api.addressy.com/EmailValidation/Interactive/Validate/v2.00/json3.ws"
  const ENDPOINT_CAPTURE_INTERACTIVE_FIND =
    "https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws"
  const ENDPOINT_CAPTURE_INTERACTIVE_RETRIVE =
    "https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws"

  const COMMONSETTINGS = {
    method: "POST",
    headers: { "Content-type": "application/x-www-form-urlencoded" }
  }

  const sanitizeSlashFromQuery = (query: string) => {
    return query.replaceAll("/", "$")
  }

  // https://www.loqate.com/resources/support/apis/EmailValidation/Interactive/Validate/2/
  const emailValidation = async (email: string = ""): Promise<boolean> => {
    const params =
      new URLSearchParams({
        Key: LOQATE_KEY,
        Email: email
      })?.toString() ?? ""

    const response = await fetch(
      `${ENDPOINT_EMAIL_VALIDATION}?${params}`,
      COMMONSETTINGS
    )
    const data = (await response.json()) as LoqateEmailValidationArray

    if (data.Items.length === 0) {
      return false
    }

    const item = data.Items[0]
    const isValid =
      item.ResponseCode === "Valid" ||
      item.ResponseCode === "Unknown" ||
      item.ResponseCode === "Valid_CatchAll" ||
      item.ResponseCode === "Timeout" ||
      item.Error === "3"

    return isValid
  }

  // https://www.loqate.com/resources/support/apis/Capture/Interactive/Find/1.1/
  const captureInteractiveFind = async (
    searchTerm: string = ""
  ): Promise<LoqateAddressFindResponse> => {
    searchTerm = sanitizeSlashFromQuery(searchTerm)
    const params =
      new URLSearchParams({
        Key: LOQATE_KEY,
        Text: searchTerm,
        Countries: "ITA"
      })?.toString() ?? ""

    const response = await fetch(
      `${ENDPOINT_CAPTURE_INTERACTIVE_FIND}?${params}`,
      COMMONSETTINGS
    )
    const data = await response.json()

    return data
  }

  // https://www.loqate.com/resources/support/apis/Capture/Interactive/Retrieve/1.2/
  const captureInteractiveRetrive = async (
    id: string = "",
    includeCoordinates?: boolean
  ): Promise<LoqateAddressRetrieveResponse> => {
    const params =
      new URLSearchParams({
        Key: LOQATE_KEY,
        Id: id
      })?.toString() ?? ""

    const coordinatesParam = includeCoordinates
      ? "&Field1Format={Latitude},{Longitude}"
      : ""

    const response = await fetch(
      `${ENDPOINT_CAPTURE_INTERACTIVE_RETRIVE}?${params}${coordinatesParam}`,
      COMMONSETTINGS
    )
    const data = await response.json()

    return data
  }

  const captureInteractiveFindMultiAddress = async (
    searchTerm: string = "",
    idContainer: string
  ) => {
    searchTerm = sanitizeSlashFromQuery(searchTerm)
    const params =
      new URLSearchParams({
        Key: LOQATE_KEY,
        Text: searchTerm,
        Container: idContainer,
        Countries: "ITA"
      })?.toString() ?? ""

    const response = await fetch(
      `${ENDPOINT_CAPTURE_INTERACTIVE_FIND}?${params}`,
      COMMONSETTINGS
    )
    const data = await response.json()

    return data
  }

  return {
    emailValidation,
    captureInteractiveFind,
    captureInteractiveRetrive,
    captureInteractiveFindMultiAddress
  }
}

export type LoqateGenericResponse<T> = {
  Items: Array<T>
}

export type LoqateEmailValidationArray =
  LoqateGenericResponse<LoqateEmailValidationItem>

export type LoqateEmailValidationItem = {
  ResponseCode: string
  ResponseMessage: string
  EmailAddress: string
  UserAccount: string
  Domain: string
  IsDisposableOrTemporary: boolean
  IsComplainerOrFraudRisk: boolean
  Duration: number
  Reason: string
  Risk: string
}

export type LoqateAddressFindResponse =
  LoqateGenericResponse<LoqateAddressCompact>

export type LoqateAddressCompact = {
  Id: string
  Type: string
  Text: string
  Highlight: string
  Description: string
}

export type LoqateAddressRetrieveResponse =
  LoqateGenericResponse<LoqateAddressInfo>

export type LoqateAddressInfo = {
  Id: string
  DomesticId: string
  Language: string
  LanguageAlternatives: string
  Department: string
  Company: string
  SubBuilding: string
  BuildingNumber: string
  BuildingName: string
  SecondaryStreet: string
  Street: string
  Block: string
  Neighbourhood: string
  District: string
  City: string
  Line1: string
  Line2: string
  Line3: string
  Line4: string
  Line5: string
  AdminAreaName: string
  AdminAreaCode: string
  Province: string
  ProvinceName: string
  ProvinceCode: string
  PostalCode: string
  CountryName: string
  CountryIso2: string
  CountryIso3: string
  CountryIsoNumber: string
  SortingNumber1: string
  SortingNumber2: string
  Barcode: string
  POBoxNumber: string
  Label: string
  Type: string
  DataLevel: string
  Field1: string
  Field2: string
  Field3: string
  Field4: string
  Field5: string
  Field6: string
  Field7: string
  Field8: string
  Field9: string
  Field10: string
  Field11: string
  Field12: string
  Field13: string
  Field14: string
  Field15: string
  Field16: string
  Field17: string
  Field18: string
  Field19: string
  Field20: ""
}
